function CheckboxUnchecked(props) {
  const { color, height, width } = props
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="3"
        stroke={color || '#1F4D25'}
        strokeWidth="2"
      />
    </svg>
  )
}

export default CheckboxUnchecked
