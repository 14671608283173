/* eslint-disable react/no-danger */
import { useEffect } from 'react'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import Head from 'next/head'
import { useRouter } from 'next/router'
import createEmotionCache from 'src/styles/createEmotionCache'
import 'src/styles/globals.css'
import theme from 'src/styles/theme'
import { removeTrailingSlash } from 'src/common/utils/js/removeTrailingSlash'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}) {
  const router = useRouter()

  useEffect(() => {
    // Removing server-side injected CSS
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
    const html = document.querySelector('html')
    const currentLang = window?.Weglot?.getCurrentLang()

    if (html && currentLang) {
      html.setAttribute('lang', currentLang)
    }
  }, [])

  useEffect(() => {
    switch (router.asPath) {
      case '/explore/attractions':
        return router.replace('/explore/park-attractions')
      case '/venues':
        return router.replace('/plan-an-event')
      default:
        return null
    }
  }, [router.asPath])

  // Restructuring breadcrumb data for schema
  const breadcrumbData = pageProps.pageData?.breadcrumb?.items || []
  const ogTag = pageProps?.pageData?.meta_data?.og || []
  const twitterTag = pageProps?.pageData?.meta_data?.twitter || []
  const relTag = pageProps?.pageData?.meta_data?.rel || []
  const title = pageProps?.pageData?.meta_data?.title || ''
  const description = pageProps?.pageData?.meta_data?.description || ''
  const keywords = pageProps?.pageData?.meta_data?.keywords || ''
  const robots = pageProps?.pageData?.meta_data?.robots || []
  const schema = pageProps.pageData?.schema || {}

  const breadcrumbItems = breadcrumbData.map(({ name, url }, index) => {
    if (index === breadcrumbData.length - 1) {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name,
      }
    }
    return {
      '@type': 'ListItem',
      position: index + 1,
      name,
      item: url ? `${process.env.NEXT_PUBLIC_SERVER_URL}${url}` : null,
    }
  })

  const faqSchema = (schemaValue) => {
    const faqSchemaStructure = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [],
    }
    schemaValue.slice(1).map((value) =>
      faqSchemaStructure.mainEntity.push({
        '@type': 'Question',
        name: value.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: value.answer,
        },
      })
    )
    return faqSchemaStructure
  }

  if (process.env.NODE_ENV !== 'development') {
    console.log = () => {}
    console.debug = () => {}
    console.info = () => {}
    console.warn = () => {}
  }

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
        {Object.keys(twitterTag)?.map((key) => (
          <meta
            property={`twitter:${key}`}
            content={twitterTag[key]}
            key={key}
          />
        ))}
        {Object.keys(ogTag)?.map((key) => (
          <meta property={`og:${key}`} content={ogTag[key]} key={key} />
        ))}
        {Object.keys(relTag)?.map((key) => (
          <link href={removeTrailingSlash(relTag[key])} rel={key} key={key} />
        ))}
        {Object.keys(robots)?.map((key) => (
          <meta
            name="robots"
            content={robots[key] ? key : key.slice(2)}
            key={key}
          />
        ))}
        {schema &&
          Object.keys(schema).length > 0 &&
          Object.keys(schema).map((key) => {
            if (key === 'faq') {
              return (
                <script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(faqSchema(schema[key])),
                  }}
                  key={key}
                />
              )
            }
            return (
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(schema[key]),
                }}
                key={key}
              />
            )
          })}
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Head>
      <ThemeProvider theme={theme}>
        {breadcrumbData.length > 1 && (
          <script
            id="breadcrumb-schema"
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement":${JSON.stringify(
                breadcrumbItems
              )}}`,
            }}
          />
        )}
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </CacheProvider>
  )
}

export default MyApp
