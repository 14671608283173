function CheckboxChecked(props) {
  const { color, height, width } = props
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="3"
          stroke={color || '#1F4D25'}
          strokeWidth="2"
        />
        <path
          d="M17.3337 8.66667L10.0003 16L6.66699 12.6667"
          stroke={color || '#1F4D25'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10550_24467">
          <rect width={width || '24'} height={height || '24'} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CheckboxChecked
